import { 
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'


const initialState = {
  getInfo: null,
  infoPopupOpen: false,
  userSeed: 'useState',
  nextServerSeed: 'nextServerSeed'
};


export const provablyFairSlice = createSlice({
  name: 'provablyFair',
  initialState,
  reducers: {
  	setGetInfo: (state, action) => {
      state.getInfo = action.payload;
    },

    setInfoPopupOpen: (state, action) => {
      state.infoPopupOpen = action.payload;
    },


    setUserSeed: (state, action) => { // TODO: update when user change
      state.userSeed = action.payload;
    },

    setNextServerSeed: (state, action) => { //TODO: update when round change
      state.nextServerSeed = action.payload;
    },
  },
})



export const { setGetInfo, setInfoPopupOpen, setNextServerSeed } = provablyFairSlice.actions

export default provablyFairSlice.reducer

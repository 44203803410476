import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLiveBettingTableCalculated } from '../../redux/betTableSlice';
import {
  getSurrentSymbol,
  getCurrentSuffix,
} from '../../redux/ConfigurationSlice';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import MultiplierValue, { rowColor } from './MultiplierValue';

const BetTable = () => {
  const { t } = useTranslation();

  const currentSymbol = useSelector(getSurrentSymbol);
  const currentSuffix = useSelector(getCurrentSuffix);
  const liveBettingTable = useSelector(getLiveBettingTableCalculated);

  return (
    <>
      <div className='table__header'>
        <div>{t('SIDEBAR.TABLE_PLAYER')}</div>
        <div>{t('SIDEBAR.TABLE_BET')}</div>
        <div>{t('SIDEBAR.TABLE_MULTI')}</div>
        <div>{t('SIDEBAR.TABLE_CASHOUT')}</div>
      </div>
      {liveBettingTable && liveBettingTable.length > 0 ? (
        <div className='table__content'>
          {liveBettingTable.map((message) => {
            return (
              <div
                key={uuidv4()}
                className={`table__row ${rowColor(message.cashout_multiplier)}`}
              >
                <div className='table__col'>{message.the_username}</div>
                <div className='table__col'>
                  {message.bet_amount.toFixed(2)}
                </div>
                <div
                  className={`table__col ${
                    message.cashout_multiplier ? 'table__col--control' : ''
                  }`}
                >
                  {message.cashout_multiplier ? (
                    <MultiplierValue
                      value={message.cashout_multiplier.toFixed(2)}
                    />
                  ) : (
                    '-'
                  )}
                </div>
                <div className='table__col'>
                  {message.profit
                    ? `${(
                        +message.bet_amount * +message.cashout_multiplier
                      ).toFixed(2)}`
                    : '-'}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='table__content'></div>
      )}
    </>
  );
};

export default BetTable;

import React, { useEffect, useState } from 'react';
import { useGame } from '../../contexts/GameContext';

import { useSelector } from 'react-redux';

import {
  getAnimationGameStatus,
  getGlobalTimeNow,
} from '../../redux/betsSlice';

import 'pixi-spine';

import * as PIXI from 'pixi.js';

import { Spine } from 'pixi-spine';

import Moon from './objects/Moon';
import Stars from './objects/Stars';
import Cloud from './objects/Cloud';
import Bird from './objects/Bird';
import Ball from './objects/Ball';
import Background from './objects/Background';
import Player from './objects/Player';
import Saturn from './objects/Saturn';
import AdditionalObject from './objects/AdditionalObject';

import './Animation.scss';

window.PIXI = PIXI;

function calculateMultiplier(sec) {
  var r = 0.00006;
  return Math.floor(100 * Math.pow(Math.E, sec * 1000 * r)) / 100;
}

let bgApp = null;
let ball = null;
let bg;
let bird;
let moon;
let stars;
let player;
let additional;
let start;
let saturn;
let clouds;

let birdTexture;
let backgroundTexture;
let playerSpine;
let ballSpine;
let moonTexture;
let starsTexture;
let saturnSpine;
let additionalObjectsData;
let cloudsTexture;

// TODO: create object stage => loadAnimationAssets, appStart, roundStart, roundEnd, bettinStart
export const loadAnimationAssets = async (config) => {
  const {
    background,
    ballPath,
    playerPath,
    additionalImages,
    saturnPath,
    birdPath,
    moonPath,
    starsPath,
    cloudsPath,
  } = config;

  const assets = await PIXI.Assets.load([
    playerPath,
    ballPath,
    background,
    ...additionalImages.map((o) => o.src),
    saturnPath,
    birdPath,
    moonPath,
    starsPath,
    ...cloudsPath,
  ]);
  birdTexture = assets[birdPath];
  backgroundTexture = assets[background];
  moonTexture = assets[moonPath];
  starsTexture = assets[starsPath];
  cloudsTexture = cloudsPath.map((c) => assets[c]);

  saturnSpine = assets[saturnPath].spineData;
  playerSpine = assets[playerPath].spineData;
  ballSpine = assets[ballPath].spineData;
  additionalObjectsData = additionalImages.map((item) => ({
    spineData: assets[item.src].spineData,
    ...item,
    // type: item.type
  }));
};

const appStart = async (container, getCurrentMultiplier) => {
  bgApp = new PIXI.Application({
    autoResize: true,
    backgroundAlpha: 0,
    resizeTo: document.querySelector(container),
  });

  document.querySelector(container).appendChild(bgApp.view);
  const initMultiplier = getCurrentMultiplier();

  //TODO:  check
  // constructor(texture, app, getCurrentMultiplier) {
  //    super(texture);
  //    this.app = app;

  //    this.app.stage.addChild(this);
  //    this.app.ticker.add(() => this.update( getCurrentMultiplier()));
  //    const initMultiplier = getCurrentMultiplier();
  // }

  stars = new Stars(starsTexture, bgApp, initMultiplier);
  moon = new Moon(moonTexture, bgApp, initMultiplier);
  saturn = new Saturn(saturnSpine, bgApp, initMultiplier);
  additional = additionalObjectsData.map(
    (item) => new AdditionalObject(item.spineData, bgApp, initMultiplier, item)
  );
  clouds = cloudsTexture.map(
    (texture) => new Cloud(texture, bgApp, initMultiplier)
  );
  bg = new Background(backgroundTexture, bgApp, initMultiplier);
  bird = new Bird(birdTexture, bgApp, initMultiplier);
  ball = new Ball(ballSpine, bgApp, initMultiplier);
  player = new Player(playerSpine, bgApp, initMultiplier, ball, bg);

  bgApp.stage.addChild(
    stars,
    moon,
    saturn.spine,
    ...additional.map((a) => a.spine),
    ...clouds,
    bg,
    bird,
    ball.spine,
    player.spine
  );

  bgApp.ticker.add(() => {
    const multiplier = getCurrentMultiplier();

    bg.update(multiplier);
    player.update(multiplier);
    ball.update(multiplier);

    bird.update(multiplier);
    moon.update(multiplier);
    stars.update(multiplier);
    saturn.update(multiplier);
    additional.forEach((a) => a.update(multiplier));
    clouds.forEach((c) => c.update(multiplier));
  });
};

export const startBettingPhase = () => {
  ball && ball.bettingPhase();
};

let timeNow;

let isBettingPhase = false;
let isCashoutPhase = false;

window.addEventListener(
  'resize',
  debounce(() => ball.resetPosition(), 250)
);


function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let args = arguments;
    let later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
}

const Animation = () => {
  const { liveMultiplier, gameStatus } = useSelector(getAnimationGameStatus);
  const globalTimeNow = useSelector(getGlobalTimeNow);

  const getCurrentMultiplier = () => {
    if (isBettingPhase) return 0;
    if (isCashoutPhase) return -1;
    let time_elapsed = (Date.now() - timeNow) / 1000.0;

    return +calculateMultiplier(time_elapsed).toFixed(2);
  };

  useEffect(() => {
    timeNow = globalTimeNow;
  }, [globalTimeNow]);

  useEffect(() => {
    appStart('#animation', getCurrentMultiplier);
  }, []);

  useEffect(() => {
    if (gameStatus == 'start_multiplier_count') {
      isBettingPhase = false;
      isCashoutPhase = false;
      //starting...
    }

    if (gameStatus == 'start_betting_phase') {
      isBettingPhase = true;
      isCashoutPhase = false;
    }

    if (gameStatus == 'stop_multiplier_count') {
      isBettingPhase = false;
      isCashoutPhase = true;
    }

    if (gameStatus == 'end') {
    }
  }, [gameStatus]);

  return <div id="animation"></div>;
};

export default React.memo(Animation);

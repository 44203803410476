import { configureStore } from '@reduxjs/toolkit';

import configuration from './ConfigurationSlice';
import betTable from './betTableSlice';
import betHistory from './betHistorySlice';
import provablyFair from './provablyFairSlice';
import user from './userSlice';
import bets from './betsSlice';
import crashHistory from './crashHistorySlice';

export const store = configureStore({
  reducer: {
    configuration,
    betTable,
    betHistory,
    provablyFair,
    user,
    bets,
    crashHistory,
  },
});

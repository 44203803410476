import React from 'react';
import Modal from './modal/Modal';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks';

import { setGetInfo } from '../../redux/provablyFairSlice';

const GameInfo = () => {
  const actions = useActions({
    setGetInfo,
  });

  const getInfo = useSelector((state) => state.provablyFair.getInfo);

  const close = () => {
    actions.setGetInfo(null);
  };

  if (!getInfo) return null;

  return (
    <Modal trigger={!!getInfo} setTrigger={() => setGetInfo(null)}>
      <div className='login-modal'>
        <div style={{ position: 'relative' }}>
          <button
            onClick={() => setGetInfo(null)}
            className='modal__close-btn'
          ></button>
          <h1>Game info</h1>
        </div>
        <div class='form-group'>
          <label>Server's seed</label>
          <input placeholder='Enter your username' value={getInfo.serverSeed} />
        </div>
        {getInfo.userSeeds.map((seed, index) => (
          <div class='form-group form-group--last'>
            <label>User{index + 1} seed</label>
            <input value={seed} />
          </div>
        ))}
        <div class='form-group form-group--last'>
          <label>Client seed</label>
          <input placeholder='Enter your password' value={getInfo.clientSeed} />
        </div>
        <div class='form-group form-group--last'>
          <label>Hash</label>
          <input placeholder='Enter your password' value={getInfo.hash} />
        </div>
        <div class='form-group form-group--last'>
          <label>Hex</label>
          <input placeholder='Enter your password' value={getInfo.hex} />
        </div>
        <div class='form-group form-group--last'>
          <label>Dex</label>
          <input placeholder='Enter your password' value={getInfo.dex} />
        </div>
        <div class='form-group form-group--last'>
          <label>Odds</label>
          <input placeholder='Enter your password' value={getInfo.odds} />
        </div>
      </div>
    </Modal>
  );
};

export default GameInfo;

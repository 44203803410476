import { 
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import * as api from '../contexts/api';


export const fetchBetHistory = createAsyncThunk( //TODO: fetch when user updated or use sockets
  'myBet/fetch',
  async () => {
    const response = await api.bet_history()
    return response.data !== 'No User Authentication' ? response.data : [];
  }
)

const initialState = {
  items: []
};

export const betHistorySlice = createSlice({
  name: 'betHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBetHistory.fulfilled, (state, action) => {
      state.items = action.payload;
    })
  },
})

export const getBetHistory = state => state.betHistory.items;

export default betHistorySlice.reducer


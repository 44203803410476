import React, { useEffect } from 'react';

import './game.scss';

import Bet from './bet/Bet';
import History from './history/History';
import Sidebar from './sidebar/Sidebar';
import GameStatus from './gameStatus/GameStatus';
import ProvablyFair from './modals/ProvablyFair';
import GameInfo from './modals/GameInfo';
import Animation from './animation/Animation';
import UserInfo from './userInfo/UserInfo';
import { ToastConteiner } from './toast';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import {
  fetchConfiguration,
  getIsAppLoading,
  getShowAnimation,
} from '../redux/ConfigurationSlice';

import { useActions } from '../hooks';

const App = () => {
  const actions = useActions({
    fetchConfiguration,
  });

  useEffect(() => {
    actions.fetchConfiguration();
  }, []);

  const showAnimation = useSelector(getShowAnimation);

  const { t } = useTranslation();

  const isAppLoading = useSelector(getIsAppLoading);

  if (isAppLoading) {
    return <div className='loading'>{t('GAME.LOADING')}</div>;
  }

  return (
    <div className='game game--inline' >
      <UserInfo />
      <ToastConteiner />
      <ProvablyFair />
      <GameInfo />
      <div className='game__info'>
        <Sidebar />
      </div>
      <div className='game__mobile-bg'></div>
      {showAnimation ? <Animation /> : null}
      <div className='game__content'>
        <div className='game_status'>
          <GameStatus />
        </div>
        <div className='game__bet-container'>
          <Bet betNumber={0} />
          <Bet betNumber={1} />
        </div>
        <div className='game__history'>
          <History />
        </div>
      </div>
    </div>
  );
};

export default App;

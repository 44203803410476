import React from 'react';
import Modal from "./modal/Modal";
import { useSelector} from 'react-redux';
import { useActions } from '../../hooks';
import { setInfoPopupOpen } from '../../redux/provablyFairSlice';

const ProvablyFair = () => {
  const {
    userSeed,
    nextServerSeed,
    infoPopupOpen,
  } = useSelector(state => state.provablyFair);


  const actions = useActions({
    setInfoPopupOpen
  });

  const close = () => {
    actions.setInfoPopupOpen(false);
  }

	return(
	    <Modal trigger={infoPopupOpen} setTrigger={close}>
      <div className="login-modal">
        <div>
          <button
            onClick={close}
            className="modal__close-btn"
          ></button>
          <h1>Provably Fair</h1>
        </div>
        <div class='form-group'>
          <label>Your next seed</label>
          <input placeholder='Enter your username' value={userSeed} />
        </div>
        <div class='form-group form-group--last'>
          <label>Server's next hash</label>
          <input placeholder='Enter your password' value={nextServerSeed} />
        </div>
      </div>
      <div></div>
    </Modal>
  );
};

export default ProvablyFair;

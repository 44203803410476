
let id;

export const initYaMetrika = (_id, verification) => {
	var meta = document.createElement('meta');
	meta.name = "yandex-verification";
	meta.content = verification;
	document.getElementsByTagName('head')[0].appendChild(meta);

//    <meta name="yandex-verification" content="6a457272a17b945a" />

/* eslint-disable no-unused-expressions */

   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   window.ym(_id, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });


/* eslint-disable-line */

	id = _id;

}

export const track = (eventName) => {
	if (!id || !window.ym) return;

	window.ym(id,'reachGoal', eventName)
}
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './css/reset.scss';
import Animation from './game/animation/Animation';
import Game from './game/Game';
import { GameProvider } from './contexts/GameContext';
import './i18n';
import io from 'socket.io-client';

import { store } from './redux/store';
import { Provider } from 'react-redux';
import { initYaMetrika } from './yaMetrika';
import config from './config';

const socket = io.connect('/');

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div className='loading'>Loading...</div>}>
        <GameProvider socket={socket}>
        </GameProvider>
        <Game />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);


function listener(event) {
  console.log('post message ', event);
}

if (window.addEventListener) {
  window.addEventListener("message", listener);
} else {
  // IE8
  window.attachEvent("onmessage", listener);
}

if (config.yaMetrika) {
  const { id, code } = config.yaMetrika;

  initYaMetrika(id, code);
}
import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { calculateScreen, isMobile } from '../utils.js';

class Player {
  constructor(texture, app, multiplier, ball, background) {
    this.spine = new Spine(texture);
    this.app = app;

    const { By, Bx, Ax, Ay } = calculateScreen(app);
    this.spine.scale.set(0.4);
    this.spine.position.set(Bx, By);
    this.spine.state.setAnimation(0, 'action_speed', false);

    if (multiplier > 1) {
      this.spine.visible = false;
    } else {
      this.spine.visible = true;
    }

    this.spine.state.tracks[0].animationStart = 4.6;
    this.spine.state.tracks[0].animationEnd = 4.6;

    this.hit = false;
    const self = this;
    this.spine.state.addListener({
      event: function (entry, event) {
        if (event.data.name == 'Hit') {
          self.hit = true;
          ball.hit();
          background.startMove();
        }
      },
    });
  }

  resetPosition() {
    this.spine.state.setAnimation(0, 'action_speed', false);
    const { By, Bx, Ax, Ay } = calculateScreen(this.app);
    this.spine.position.set(Bx, By);
    this.spine.visible = false;
    if (isMobile()) {
      this.spine.scale.set(0.3);
    } else {
      this.spine.scale.set(0.4);
    }
  }

  update(multiplier) {
    if (multiplier == -1) {
      this.resetPosition();
      this.hit = false;
    }

    if (multiplier == 0) {
      this.spine.visible = true;
    }

    if (this.hit) {
      this.spine.x -= 5;
      this.spine.y += 5;
    }
  }
}

export default Player;

import Axios from 'axios';

const getToken = () => {
  let params = new URL(document.location).searchParams;
  let code = params.get('code');

  return 'Bearer ' + code;
};

export const getUser = () =>
  Axios({
    method: 'GET',
    // withCredentials: true,
    url: '/api/game/user-details',
    headers: {
      Authorization: getToken(),
    },
  });

export const bet_history = () =>
  Axios.get('/api/game/bet_history', {
    headers: {
      Authorization: getToken(),
    },
  });

export const auto_cashout_early = (bet_number) =>
  Axios({
    url: '/api/game/auto_cashout_early',
    method: 'POST',
    data: {
      bet_number,
    },
    headers: {
      Authorization: getToken(),
    },
  });

export const manual_cashout_early = (bet_number) =>
  Axios({
    url: '/api/game/manual_cashout_early',
    method: 'POST',
    data: {
      bet_number,
    },
    headers: {
      Authorization: getToken(),
    },
  });

// export const send_bet = ({
// 	bet_amount,
// 	payout_multiplier,
// 	bet_number
// }) => Axios({
// 	method: "POST",
// 	data: {
// 		bet_amount,
// 		payout_multiplier,
// 		bet_number,
// 	},
// 	withCredentials: true,
// 	url: API_BASE + "/send_bet",
// })

let request;
export const send_bet = ({ bet_amount, payout_multiplier, bet_number }) => {
  if (request)
    return request.then(() =>
      Axios({
        method: 'POST',
        data: {
          bet_amount: +bet_amount,
          payout_multiplier,
          bet_number,
        },
        headers: {
          Authorization: getToken(),
        },
        url: '/api/game/send_bet',
      })
        .then((res) => {
          request = null;
          return res;
        })
        .catch((e) => {
          request = null;
          throw e;
        })
    );
  else {
    request = Axios({
      method: 'POST',
      data: {
        bet_amount: +bet_amount,
        payout_multiplier,
        bet_number,
      },
      headers: {
        Authorization: getToken(),
      },
      url: '/api/game/send_bet',
    })
      .then((res) => {
        request = null;
        return res;
      })
      .catch((e) => {
        request = null;
        throw e;
      });

    return request;
  }
};

export const getConfiguration = () => Axios.get('/api/game/configuration');

export const get_game_status = () =>
  Axios.get('/api/game/get_game_status', {
    headers: {
      Authorization: getToken(),
    },
  });

import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

const initialState = {
  betsActive: [0, 0],
  liveMultiplier: 'Loading...',
  liveMultiplierSwitch: false,
  bBettingPhase: false,
  bettingPhaseTime: -1,
  globalTimeNow: 0,
  gamePhaseTimeElapsed: 0,
  gameStatus: '',
};

export const betsSlice = createSlice({
  name: 'betsSlice',
  initialState,
  reducers: {
    setLiveMultiplier: (state, action) => {
      state.liveMultiplier = action.payload;
    },
    setBetsActive: (state, action) => {
      state.betsActive = action.payload;
    },
    setLiveMultiplierSwitch: (state, action) => {
      state.liveMultiplierSwitch = action.payload;
    },
    setbBettingPhase: (state, action) => {
      state.bBettingPhase = action.payload;
    },
    setBettingPhaseTime: (state, action) => {
      state.bettingPhaseTime = action.payload;
    },
    setGlobalTimeNow: (state, action) => {
      state.globalTimeNow = action.payload;
    },
    setGamePhaseTimeElapsed: (state, action) => {
      state.gamePhaseTimeElapsed = action.payload;
    },
    setGameStatus: (state, action) => {
      state.gameStatus = action.payload;
    },
  },
});

export const getBetsActive = state => state.bets.betsActive;
export const getLiveMultiplier = state => state.bets.liveMultiplier;
export const getLiveMultiplierSwitch = state => state.bets.liveMultiplierSwitch;
export const getBettingPhase = state => state.bets.bBettingPhase;
export const getGameStatus = state => state.bets.gameStatus;

export const getGlobalTimeNow = createSelector([
  state => state.bets.globalTimeNow
], (globalTimeNow) => globalTimeNow);

export const getAnimationGameStatus = createSelector([
  state => state.bets.gameStatus, 
  state => state.bets.liveMultiplierSwitch
], (gameStatus, liveMultiplierSwitch) => {
  return { gameStatus, liveMultiplier: !liveMultiplierSwitch ? 'Starting...' : 'x' }
});

export const {
  setLiveMultiplier,
  setBetsActive,
  setLiveMultiplierSwitch,
  setbBettingPhase,
  setBettingPhaseTime,
  setGlobalTimeNow,
  setGamePhaseTimeElapsed,
  setGameStatus,
} = betsSlice.actions;

export default betsSlice.reducer;

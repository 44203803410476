import React from 'react';
import Checkbox from './Checkbox';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';

const AutoCashout = ({
  checked,
  onChange,
  handleKeyDownBetting,
  verifyMultiplierAmount,
  autoPayoutMultiplier,
  disabled,
  multiplierOnBlur,
  isMobile,
}) => {
  const { t } = useTranslation();
  const handleOnFocus = (e) => {
    return (e.target.value = autoPayoutMultiplier);
  };

  const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

function whatDecimalSeparator() {
    var n = 1.1;
    n = n.toLocaleString().substring(1, 2);
    return n;
}

  return (
    <div
      className={`control control--fild ${disabled ? 'control--disabled' : ''}`}
    >
      <div className="control__main ">
        <Checkbox
          label={t('BET.AUTO_CASHOUT')}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {!checked ? (
        // <label className="bet__multiplier-input-label">
        // 	<span>x</span>
        // 	<input
        // 		className="bet__multiplier-input"
        // 		onChange={(e) => verifyMultiplierAmount(e.target.value)}
        // 		onKeyDown={handleKeyDownBetting}
        // 		value={autoPayoutMultiplier}
        // 		disabled={disabled ? 'disabled' : null}
        //   />
        // </label>
        <CurrencyInput
          className="bet__multiplier-input"
          name="multiplier-input"
          defaultValue={2}
          allowNegativeValue={false}
          prefix="x"
          disabled={disabled}
          decimalsLimit={2}
          groupSeparator={' '}
          decimalSeparator={_iOSDevice ? ',' : '.'}
          onValueChange={(value) =>
            verifyMultiplierAmount(value ? value.replace(',', '.'): value)
          }
          value={autoPayoutMultiplier}
          onBlur={() => multiplierOnBlur(autoPayoutMultiplier)}
          onFocus={(e) => isMobile && handleOnFocus(e)}
        />
      ) : (
        <span className="bet__multiplier-label">x{autoPayoutMultiplier}</span>
      )}
    </div>
  );
};

export default AutoCashout;

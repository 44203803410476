import * as PIXI from 'pixi.js';
import {
  animateBackgroundColor,
  BG_HEIGHT,
  BG_WIDTH,
  isMobile,
} from '../utils.js';

class Background extends PIXI.Sprite {
  constructor(texture, app, multiplier) {
    super(texture);
    this.app = app;

    // if (isMobile()) {
    //   this.scale.set(0.4);
    // }
    this.resetPosition();

    if (multiplier > 1) {
      this.visible = false;
    } else {
      this.visible = true;
    }

    this.hit = false;
  }

  startMove() {
    this.hit = true;
  }

  resetPosition() {
    this.y = this.app.renderer.height - this.height;
    this.scale.set(this.app.renderer.width / BG_WIDTH + 0.3);
    // isMobile() ? this.scale.set(0.4) : this.scale.set(1);
    this.x = 0;
    this.visible = false;
  }

  update(multiplier) {
    if (multiplier == -1) {
      this.resetPosition();
      this.hit = false;
    }

    if (multiplier == 0) {
      this.visible = true;
    }

    if (this.hit || multiplier > 1.3) {
      this.x -= 5;
      this.y += 5;
    }

    if (multiplier >= 0) {
      animateBackgroundColor(multiplier, this.app);
    }
  }
}

export default Background;

import * as PIXI from "pixi.js";
import { Spine } from "pixi-spine";
import { random, isMobile } from "../utils.js";

// TODO: move all if in configuration
class AdditionalObject {
  constructor(spineData, app, multiplier, item) {
    this.spine = new Spine(spineData);
    this.app = app;
    const { type, startX, speedX, speedY, rotate, displayChance } = item;
    this.type = type;
    this.startX = startX;

    this.speedX = speedX;
    this.speedY = speedY;
    if (isMobile()) {
      this.spine.scale.set(0.2);
    } else {
      this.spine.scale.set(0.3);
    }

    this.spine.state.setAnimation(0, "animation", true);
    this.spine.state.timeScale = 1;

    this.displayChance = displayChance;
    this.display = random(0, this.displayChance);

    this.resetPosition();
    if (rotate) {
      this.spine.rotation = rotate;
    }

    this.spine.visible = multiplier > this.startX;
  }

  resetPosition() {
    if (this.type === "left") {
      this.spine.x = -this.spine.width;
      this.spine.y = random(100, this.app.renderer.height - 200);
    }

    if (this.type === "top") {
      this.spine.x = random(100, this.app.renderer.width - 100);
      this.spine.y = -this.spine.height;
    }

    if (this.type === "bottom") {
      this.spine.x = random(0, this.app.renderer.width);
      this.spine.y = this.app.renderer.height + this.spine.height;
    }

    if (this.type === "right") {
      this.spine.x = this.app.renderer.width + this.spine.width;
      this.spine.y = random(100, this.app.renderer.height - 200);
    }
  }

  update(multiplier) {
    if (multiplier === -1) return;

    if (multiplier === 0) {
      this.spine.visible = false;
      this.resetPosition();
      this.display = random(0, this.displayChance);
    }

    if (
      multiplier > this.startX &&
      multiplier < this.startX + 5 &&
      this.display === 0
    ) {
      this.spine.visible = multiplier > this.startX;
      this.spine.x += this.speedX;
      this.spine.y += this.speedY;
    } else {
      this.spine.visible = false;
    }
  }
}

export default AdditionalObject;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getUserData } from './userSlice';

const initialState = {
  liveBettingTable: [],
};

export const liveBettingTableSlice = createSlice({
  name: 'liveBettingTable',
  initialState,
  reducers: {
    setLiveBettingTable: (state, action) => {
      state.liveBettingTable = action.payload;
    },
    addLiveBettingTable: (state, action) => {
      state.liveBettingTable = state.liveBettingTable.concat([action.payload]);
    },
    updateLiveBettingTable: (state, action) => {
      const newBet = action.payload;
      state.liveBettingTable = state.liveBettingTable.map(bet => {
        if (bet.user_id == newBet.user_id && bet.bet_number === newBet.bet_number) {
          return newBet;
        }
        return bet;
      })
    }
  },
});

// const liveBettingTableCalculated = useMemo(() => {
//   let currency = 'MXN';
//   if (userData) {
//     currency = userData.currency;
//   }

//   let currencies = {};
//   if (configuration) {
//     currencies = configuration.currencies;
//   }

//   return (liveBettingTable || []).map((row) => ({
//     ...row,
//     bet_amount:
//       row.currency !== currency
//         ? (currencies[row.currency + '-' + currency] || 1) * row.bet_amount
//         : row.bet_amount,
//     cashout_multiplier:row.cashout_multiplier,
//     profit:
//       row.currency !== currency
//         ? (currencies[row.currency + '-' + currency] || 1) * row.profit
//         : row.profit,
//   }));
// }, [userData, configuration, liveBettingTable]);

export const getLiveBettingTableCalculated = (state) => {
  const liveBettingTable = state.betTable.liveBettingTable;
  const userData = getUserData(state);

  let currency = 'BRL';
  if (userData) {
    currency = userData.currency;
  }

  let currencies = {};
  // if (configuration) { //TODO: fix currencies
  //   currencies = configuration.currencies;
  // }

  // console.log('liveBettingTable ', liveBettingTable, currencies)

  return (liveBettingTable || []).map((row) => ({
    ...row,
    bet_amount:
      row.currency !== currency
        ? (currencies[row.currency + '-' + currency] || 1) * row.bet_amount
        : row.bet_amount,
    cashout_multiplier: row.cashout_multiplier,
    profit:
      row.currency !== currency
        ? (currencies[row.currency + '-' + currency] || 1) * row.profit
        : row.profit,
  }));
};

export const getBetCount = (state) => {
  const liveBettingTable = state.betTable.liveBettingTable || [];

  if (liveBettingTable && liveBettingTable.length > 0) {
    const users = liveBettingTable;

    return users.length;
  }

  return 0;
};

export const getTotalBank = (state) => {
  const liveBettingTable = state.betTable.liveBettingTable || [];

  if (liveBettingTable && liveBettingTable.length > 0) {
    const users = liveBettingTable;

    return users.reduce((acc, item) => +acc + +item.bet_amount, 0).toFixed(0);
  }

  return 0;
};

export const getTookBet = (state) => {
  const liveBettingTable = state.betTable.liveBettingTable || [];

  if (liveBettingTable && liveBettingTable.length > 0) {
    const users = liveBettingTable;

    return users.filter((x) => !!x.cashout_multiplier).length;
  }

  return 0;
};

export const { 
  setLiveBettingTable,
  addLiveBettingTable,
  updateLiveBettingTable
} = liveBettingTableSlice.actions;

export default liveBettingTableSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../contexts/api';
import i18next from 'i18next';

import { changeLanguage } from '../game/helpers';

import { setBetsActive } from './betsSlice';

export const fetchUser = createAsyncThunk(
  'user/fetch',
  async (params, thunkApi) => {
    try {
      const response = await api.getUser();

      const user = response.data;

      thunkApi.dispatch(setBetsActive(user.bet_amounts))
      changeLanguage(user.currency, i18next.language);

      return user;
    } catch (err) {
      // return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  userData: null,
  openModalLogin: false,
  openModalRegister: false,

  registerFromBonusPopup: false,

  gameUrl: '/?code=1'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setOpenModalLogin: (state, action) => {
      state.openModalLogin = action.payload;
    },
    setOpenModalRegister: (state, action) => {
      state.registerFromBonusPopup = false;
      state.openModalRegister = action.payload;
    },
    openRegisterFromBonusPopup: (state, action) => {
      state.registerFromBonusPopup = true;
      state.openModalRegister = true;
    },
    openLogin: (state) => {
      state.openModalLogin = true;
      state.openModalRegister = false;
    },
    openRegister: (state) => {
      state.openModalLogin = false;
      state.openModalRegister = true;
    },
    setUserData: (state, action) => {
      // TODO: rewrite do not set user from UI
      state.userData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
  },
});


export const getCurrencyInfo = (state) => {
  const userData = getUserData(state);

  const currencyInfo = state.configuration.currencyInfo;

  if (!userData || userData === 'No User Authentication')
    return currencyInfo['NOT_AUTH'];

  return currencyInfo[userData.currency] || currencyInfo['NOT_AUTH'];
};

export const getUserData = (state) => state.user.userData;

export const getIsUserAuth = (state) => {
  const userData = getUserData(state);

  return userData && userData !== 'No User Authentication';
};

export const {
  setOpenModalLogin,
  openRegister,
  openLogin,
  setOpenModalRegister,
  setUserData,
  openRegisterFromBonusPopup,
} = userSlice.actions;

export default userSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  crashHistoryItems: [],
};

export const crashHistorySlice = createSlice({
  name: 'crashHistory',
  initialState,
  reducers: {
    setCrashHistory: (state, action) => {
      state.crashHistoryItems = action.payload;
    },
  },
});

export const getCrashHistory = (state) =>
  state.crashHistory.crashHistoryItems || [];

export const { setCrashHistory } = crashHistorySlice.actions;

export default crashHistorySlice.reducer;

import React, { useState, useEffect, useMemo } from 'react';

import * as api from '../../contexts/api';
import CurrencyInput from 'react-currency-input-field';
import { errorToast, winToast } from '../toast';
import AutoBet from './AutoBet';
import AutoCashout from './AutoCashout';
import './Bet.scss';
import { useTranslation } from 'react-i18next';
import { getSelectedBalance } from '../helpers';

import { useSelector } from 'react-redux';
import { useActions } from '../../hooks';
import {
  login,
  openLogin,
  getCurrencyInfo,
  getUserData,
  setUserData,
} from '../../redux/userSlice';

import {
  getBetsActive,
  getLiveMultiplier,
  getLiveMultiplierSwitch,
  getBettingPhase,
  getGameStatus,
} from '../../redux/betsSlice';

// move logic to the useBet hook

// export const getBetsActive = state => state.bets.betsActive;
// export const getLiveMultiplier = state => state.bets.liveMultiplier;
// export const getLiveMultiplierSwitch = state => state.bets.liveMultiplierSwitch;
// export const getBettingPhase = state => state.bets.bBettingPhase;

const Bet = ({ betNumber }) => {
  // console.log('betNumber ', betNumber);
  // if (betNumber === 0) {
  //   console.count('bet');
  // }
  // const { betsActive, liveMultiplier, liveMultiplierSwitch, bBettingPhase } =
  //   useSelector((state) => state.bets);

  const betsActive = useSelector(getBetsActive);
  // const liveMultiplier = useSelector(getLiveMultiplier);
  const liveMultiplierSwitch = useSelector(getLiveMultiplierSwitch);
  const bBettingPhase = useSelector(getBettingPhase);
  const gameStatus = useSelector(getGameStatus);
  const liveMultiplier = useSelector(getLiveMultiplier);

  // console.log('liveMultiplierSwitch ', liveMultiplierSwitch)

  const userData = useSelector(getUserData);
  const currencyInfo = useSelector(getCurrencyInfo);
  const actions = useActions({
    openLogin,
    setUserData,
  });

  const { currentSymbol, currentSuffix, plusValues, betStep, minBet, maxBet } =
    currencyInfo;

  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(false);

  const [betActive, setBetActive] = useState(false);
  const [hookToNextRoundBet, setHookToNextRoundBet] = useState(false);
  const [bBetForNextRound, setbBetForNextRound] = useState(false);

  const prefix = useMemo(() => {
    if (userData === 'No User Authentication' || !userData) {
      return null;
    }

    return '' + betNumber + userData?._id;
  }, [userData, betNumber]);

  // UI ------------------------------------------
  const [autoCashOut, setAutoCashOut] = useState(false);
  const [autoBet, setAutoBet] = useState(false);
  const [betAmount, setBetAmount] = useState(minBet);
  const [autoPayoutMultiplier, setAutoPayoutMultiplier] = useState(2);
  // ---------------------------------------------------

  useEffect(() => {
    if (!userData || userData === 'No User Authentication') {
      setBetAmount(minBet);
      setAutoPayoutMultiplier(2);
    } else {
      setBetAmount(+localStorage.getItem(prefix + 'betAmount') || minBet);
    }
  }, [userData, minBet]);

  useEffect(() => {
    if (!prefix) {
      return;
    }

    localStorage.setItem(prefix + 'betAmount', betAmount);
    localStorage.setItem(prefix + 'multiplier', autoPayoutMultiplier);
  }, [betAmount, autoPayoutMultiplier]);

  useEffect(() => {
    if (window.innerWidth < 1000) setIsMobile(true);
    else setIsMobile(false);
  }, [window.innerWidth]);

  const auto_cashout_early = () => {
    api
      .auto_cashout_early(betNumber)
      .then((res) => {
        actions.setUserData(res.data.user);
        setBetActive(false);

        const { multiplier, money, betAmount } = res.data.bet;

        winToast(betAmount * multiplier, multiplier, t, currencyInfo);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          errorToast(
            t('TOAST.ALREADY_BETTING_TITLE'),
            t('TOAST.ALREADY_BETTING_DESCRIPTION')
          );
        }
        //COMMAN_ERROR

        if (err.response.status === 400) {
          actions.setUserData(err.response.data.user);
          setBetActive(false);
          setAutoBet(false);
          errorToast(
            t('TOAST.COMMAN_ERROR_TITLE'),
            t('TOAST.COMMAN_ERROR_TEXT')
          );
        }
      });
  };

  const send_bet = () => {
    api
      .send_bet({
        bet_amount: betAmount,
        payout_multiplier: autoCashOut ? autoPayoutMultiplier : 0,
        bet_number: betNumber,
      })
      .then((res) => {
        setBetActive(true);
        actions.setUserData(res.data.user);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          errorToast(
            t('TOAST.ALREADY_BETTING_TITLE'),
            t('TOAST.ALREADY_BETTING_DESCRIPTION')
          );
        }

        if (err.response.status === 400) {
          setBetActive(false);
          setAutoBet(false);

          if (err.response.data?.errorCode == 3) {
            errorToast(
              t('TOAST.NO_FUNDS_ERROR_TITLE'),
              t('TOAST.NO_FUNDS_ERROR_DESCRIPTION')
            );
          } else {
            errorToast(
              t('TOAST.COMMAN_ERROR_TITLE'),
              t('TOAST.COMMAN_ERROR_TEXT')
            );
          }
        }
      });
  };

  const manual_cashout_early = () => {
    api
      .manual_cashout_early(betNumber)
      .then((res) => {
        actions.setUserData(res.data.user);
        setBetActive(false);
        const { multiplier, money, betAmount } = res.data.bet;

        winToast(betAmount * multiplier, multiplier, t, currencyInfo);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          errorToast(
            t('TOAST.ALREADY_BETTING_TITLE'),
            t('TOAST.ALREADY_BETTING_DESCRIPTION')
          );
        }

        if (err.response.status === 400) {
          actions.setUserData(err.response.data.user);
          setBetActive(false);
          setAutoBet(false);
          errorToast(
            t('TOAST.COMMAN_ERROR_TITLE'),
            t('TOAST.COMMAN_ERROR_TEXT')
          );
        }
      });
  };

  /// auto cash out
  useEffect(() => {
    if (
      betActive &&
      +autoPayoutMultiplier <= +liveMultiplier &&
      autoCashOut &&
      userData &&
      userData !== 'No User Authentication'
    ) {
      const nextUserData = { ...userData };
      if (nextUserData.selectedBalanceType === 'main') {
        nextUserData.mainBalance += betAmount * autoPayoutMultiplier;
      } else {
        nextUserData.bonusBalance += betAmount * autoPayoutMultiplier;
      }
      auto_cashout_early();
      setBetActive(false);
    }
    // if (liveMultiplier === 'Starting...') {
    //   setHookToNextRoundBet(true);
    // }
  }, [liveMultiplier]);
  // ---------------------------------------------------

  // gameStatus

  useEffect(() => {
    if (gameStatus === 'start_betting_phase') {
      setHookToNextRoundBet(true);
    }
  }, [gameStatus]);

  // auto bet
  useEffect(() => {
    if (bBettingPhase) {
      if (autoBet) {
        if (+betAmount <= getSelectedBalance(userData)) {
          send_bet();
        } else {
          setBetAmount(Math.floor(+getSelectedBalance(userData) * 10) / 10);
          setAutoBet(false);

          errorToast(
            t,
            t('TOAST.NO_FUNDS_ERROR_TITLE'),
            t('TOAST.NO_FUNDS_ERROR_DESCRIPTION')
          );
        }
      }
    }
  }, [bBettingPhase]);
  // ---------------------------------------------

  useEffect(() => {
    if (!liveMultiplierSwitch) {
      // on end round set active false
      setBetActive(false);
    }
  }, [liveMultiplierSwitch]);
  // -----------------------------------

  useEffect(() => {
    if (betsActive[betNumber] > 0) setBetActive(true);
  }, [betsActive]);

  useEffect(() => {
    if (hookToNextRoundBet) {
      if (bBetForNextRound && !autoBet) {
        send_bet();
      } else {
        setHookToNextRoundBet(false);
        setbBetForNextRound(false);
      }
      setHookToNextRoundBet(false);
      setbBetForNextRound(false);
    }
  }, [hookToNextRoundBet, autoBet]);

  const bet_next_round = () => {
    setbBetForNextRound(!bBetForNextRound); //
  };

  const checkAndSetValue = (inputValue, min, max) => {
    console.dir(inputValue);
    console.log(inputValue);
    console.log(typeof inputValue);
    setBetAmount(inputValue);
    if (+inputValue > max) {
      setBetAmount(max);
      errorToast(
        t('TOAST.WARNING_TITLE'),
        t('TOAST.MAX_BET_WARNING', {
          maxBet: `${currentSymbol}${maxBet}${currentSuffix}`,
        })
      );
    }
    if (+inputValue < min) {
      setBetAmount(min);
      errorToast(
        t('TOAST.WARNING_TITLE'),
        t('TOAST.MIN_BET_WARNING', {
          minBet: `${currentSymbol}${minBet}${currentSuffix}`,
        })
      );
    }
  };

  // const [values, setValues] = useState();
  // const [rawValue, setRawValue] = useState(' ');

  // const verifyBetAmount = (text, _, values) => {
  //   setValues(values);
  //   setRawValue(text === undefined ? 'undefined' : text || ' ');

  //   if (!text) {
  //     setBetAmount('');

  //     return;
  //   }

  //   setBetAmount(text);
  // };

  const betOnBlur = (value) => {
    // console.log('userData ', userData);
    if (!userData) {
      console.log('no user data');
      return;
    }

    if (value > getSelectedBalance(userData)) {
      errorToast(
        t,
        t('TOAST.NO_FUNDS_ERROR_TITLE'),
        t('TOAST.NO_FUNDS_ERROR_DESCRIPTION')
      );

      return;
    }

    if (!value || +value < 0.1) {
      setBetAmount(minBet);

      return;
    }
  };

  const [values1, setValues1] = useState();
  const [rawValue1, setRawValue1] = useState(' ');

  const verifyMultiplierAmount = (text, _, values) => {
    setValues1(values);
    setRawValue1(text === undefined ? 'undefined' : text || ' ');

    if (!text) {
      setAutoPayoutMultiplier('');

      return;
    }
    setAutoPayoutMultiplier(text);
  };

  const multiplierOnBlur = (value) => {
    if (!value || value < 1.01) {
      setAutoPayoutMultiplier(1.01);

      return;
    }
  };

  const handleKeyDownBetting = (e) => {
    if (e.key === 'Enter') {
      if (bBettingPhase) {
        send_bet();
      } else {
        bet_next_round();
      }
    }
  };

  const handle_manual_cashout_early = () => {
    if (userData && userData !== 'No User Authentication') {
      //      if (betActive && liveMultiplier > 1) manual_cashout_early();
      if (betActive) manual_cashout_early();
    } else {
      actions.openLogin();
    }
  };

  const handle_bet_next_round = () => {
    if (userData && userData !== 'No User Authentication') {
      bet_next_round();
    } else {
      actions.openLogin();
    }
  };

  const onCancel = () => {
    setAutoBet(false);
    setbBetForNextRound(false);
  };

  const handel_send_bet = () => {
    if (userData && userData !== 'No User Authentication') {
      send_bet();
    } else {
      actions.openLogin();
    }
  };

  const handelChangeAmount = (delta) => {
    if (autoBet) return;

    if (userData && userData !== 'No User Authentication') {
      checkAndSetValue(+(+betAmount + delta).toFixed(1), minBet, maxBet);
    } else {
      actions.openLogin();
    }
  };

  const ifLogin = (cb) => {
    if (userData && userData !== 'No User Authentication') {
      cb();
    } else {
      actions.openLogin();
    }
  };

  const handleOnFocus = (e) => {
    return (e.target.value = betAmount);
  };

  const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

  return (
    <div className="bet">
      <div className="bet__row">
        <div className="bet__col bet__auto-bet-col">
          <AutoBet
            checked={autoBet}
            onChange={() =>
              ifLogin(() => {
                if (autoBet && bBetForNextRound) onCancel();
                setAutoBet(!autoBet);
                localStorage.setItem(
                  'local_storage_betIsActive' + betNumber,
                  !autoBet
                );
              })
            }
          />
        </div>
        <div className="bet__col bet__auto-cashout-col">
          <AutoCashout
            checked={autoCashOut}
            onChange={() => ifLogin(() => setAutoCashOut(!autoCashOut))}
            verifyMultiplierAmount={verifyMultiplierAmount}
            handleKeyDownBetting={handleKeyDownBetting}
            autoPayoutMultiplier={autoPayoutMultiplier}
            disabled={betActive}
            multiplierOnBlur={multiplierOnBlur}
            isMobile={isMobile}
          />
        </div>
      </div>
      <div className="bet__container">
        <div className="bet__amount-container">
          <div className="bet__amount-input-container">
            <button
              disabled={betActive || autoBet ? 'disabled' : null}
              className={`bet__amount-minus ${betActive ? 'bet__amount-minus--disabled' : ''
                }`}
              onClick={() => handelChangeAmount(-betStep)}
            >
              -
            </button>
            {!autoBet ? (
              <CurrencyInput
                className="bet__amount-input"
                name="amount-input"
                defaultValue={minBet}
                allowNegativeValue={false}
                prefix={currentSymbol}
                disableAbbreviations={true}
                suffix={currentSuffix}
                disabled={betActive}
                decimalsLimit={1}
                // intlConfig={{ ocale: 'en-US', currency: 'GBP' }}
                groupSeparator={' '}
                decimalSeparator={_iOSDevice ? "," : '.'}
                // onValueChange={(value) => verifyBetAmount(value)}
                onValueChange={(value) =>
                  checkAndSetValue(value ? value.replace(',', '.') : value, minBet, maxBet)
                }
                onBlur={() => betOnBlur(betAmount)}
                onKeyDown={handleKeyDownBetting}
                value={betAmount}
                onFocus={(e) => isMobile && handleOnFocus(e)}
              // transformRawValue={(a, b, c) => {
              //   console.log(a, b, c);
              //   return +a;
              // }}
              />
            ) : (
              <span
                className={`bet__amount-input bet__amount-input--fild ${betActive || autoBet ? 'bet__amount-input--disabled' : ''
                  }`}
              >
                {currentSymbol}
                {betAmount}
                {currentSuffix}
              </span>
            )}
            <button
              disabled={betActive || autoBet ? 'disabled' : null}
              className={`bet__amount-plus`}
              onClick={() => handelChangeAmount(betStep)}
            >
              +
            </button>
          </div>
          <div className="bet_pluse-container">
            <button
              disabled={betActive || autoBet ? 'disabled' : null}
              className={`bet_pluse-button`}
              onClick={() => handelChangeAmount(plusValues[0])}
            >
              +{plusValues[0]}
              {currentSuffix}
            </button>
            <button
              disabled={betActive || autoBet ? 'disabled' : null}
              className={`bet_pluse-button`}
              onClick={() => handelChangeAmount(plusValues[1])}
            >
              +{plusValues[1]}
              {currentSuffix}
            </button>
            <button
              disabled={betActive || autoBet ? 'disabled' : null}
              className={`bet_pluse-button`}
              onClick={() => handelChangeAmount(plusValues[2])}
            >
              +{plusValues[2]}
              {currentSuffix}
            </button>
          </div>
        </div>
        {/*        <div className='bet__button-container'>
          {bBettingPhase && !betActive && !autoBet ? (
            <button className='bet__button' onClick={handel_send_bet}>
              {<span>{t('BET.BET')}</span>}
            </button>
          ) : (
            <>
              {betActive ? (
                <button
                  className='bet__button bet__button--take'
                  onClick={handle_manual_cashout_early}
                  disabled={betActive && liveMultiplier > 1 ? null : 'disabled'}
                >
                  {' '}
                  {betActive && liveMultiplier > 1 ? (
                    <span>
                      {currentSymbol}
                      {(liveMultiplier * betAmount).toFixed(2)}
                      {currentSuffix} <br />{' '}
                      <span className='bet__button-small-text'>
                        {t('BET.CASHOUT')}
                      </span>
                    </span>
                  ) : (
                    <span>{t('BET.STARTING')}</span>
                  )}
                </button>
              ) : bBetForNextRound || autoBet ? (
                <button
                  className={`bet__button ${
                    bBetForNextRound || autoBet ? 'bet__button--cancel' : ''
                  }`}
                  onClick={() => onCancel()}
                >
                  <span>{t('BET.CANCEL')}</span>{' '}
                </button>
              ) : (
                <button
                  className={`bet__button`}
                  onClick={handle_bet_next_round}
                >
                  <span>
                    {t('BET.BET')}
                    <br />{' '}
                    <span className='bet__button-small-text'>
                      {t('BET.NEXT_ROUND')}
                    </span>
                  </span>
                </button>
              )}
            </>
          )}
        </div>*/}
        <BetButton
          betActive={betActive}
          autoBet={autoBet}
          handle_manual_cashout_early={handle_manual_cashout_early}
          betAmount={betAmount}
          handel_send_bet={handel_send_bet}
          bBetForNextRound={bBetForNextRound}
          onCancel={onCancel}
          handle_bet_next_round={handle_bet_next_round}
          autoPayoutMultiplier={autoPayoutMultiplier}
          autoCashOut={autoCashOut}
          auto_cashout_early={auto_cashout_early}
        />
      </div>
    </div>
  );
};

const BetButton = ({
  betActive,
  autoBet,
  handle_manual_cashout_early,
  betAmount,
  handel_send_bet,
  bBetForNextRound,
  onCancel,
  handle_bet_next_round,
  // autoPayoutMultiplier,
  // autoCashOut,
  // auto_cashout_early,
}) => {
  const { t } = useTranslation();
  const liveMultiplier = useSelector(getLiveMultiplier);
  const bBettingPhase = useSelector(getBettingPhase);
  const userData = useSelector(getUserData);

  const currencyInfo = useSelector(getCurrencyInfo);
  const { currentSymbol, currentSuffix } = currencyInfo;

  // useEffect(() => {

  //   if (
  //     betActive &&
  //     +autoPayoutMultiplier <= +liveMultiplier &&
  //     autoCashOut &&
  //     userData &&
  //     userData !== 'No User Authentication'
  //   ) {
  //     auto_cashout_early();
  //   }

  // }, [liveMultiplier]);

  return (
    <div className="bet__button-container">
      {bBettingPhase && !betActive && !autoBet ? (
        <button className="bet__button" onClick={handel_send_bet}>
          {<span>{t('BET.BET')}</span>}
        </button>
      ) : (
        <>
          {betActive ? (
            <button
              className="bet__button bet__button--take"
              onClick={handle_manual_cashout_early}
              disabled={betActive && liveMultiplier > 1 ? null : 'disabled'}
            >
              {' '}
              {betActive && liveMultiplier > 1 ? (
                <span>
                  {currentSymbol}
                  {(liveMultiplier * betAmount).toFixed(2)}
                  {currentSuffix} <br />{' '}
                  <span className="bet__button-small-text">
                    {t('BET.CASHOUT')}
                  </span>
                </span>
              ) : (
                <span>{t('BET.STARTING')}</span>
              )}
            </button>
          ) : bBetForNextRound || autoBet ? (
            <button
              className={`bet__button ${bBetForNextRound || autoBet ? 'bet__button--cancel' : ''
                }`}
              onClick={() => onCancel()}
            >
              <span>{t('BET.CANCEL')}</span>{' '}
            </button>
          ) : (
            <button className={`bet__button`} onClick={handle_bet_next_round}>
              <span>
                {t('BET.BET')}
                <br />{' '}
                <span className="bet__button-small-text">
                  {t('BET.NEXT_ROUND')}
                </span>
              </span>
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Bet;

import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';

import * as api from '../contexts/api';

import { loadAnimationAssets } from '../game/animation/Animation';

import { getCurrencyInfo } from './userSlice';

export const fetchConfiguration = createAsyncThunk(
  'configuration/fetch',
  async () => {
    const response = await api.getConfiguration();
    await loadAnimationAssets(response.data.animation);

    return response.data;
  }
);

export const getIsAppLoading = (state) => state.configuration.isAppLoading;

export const setShowAnimation = createAsyncThunk(
  'configuration/setAnimation',
  async (animation) => {
    localStorage.setItem('animation', JSON.stringify(animation));
    return animation;
  }
);

const storedAnimation = localStorage.getItem('animation');

const initialState = {
  isAppLoading: true,
  animation: storedAnimation !== null ? JSON.parse(storedAnimation) : true,
  currencyInfo: {},
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setAppLoading: (state, action) => {
      state.isAppLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.currencyInfo = action.payload.currencyInfo;
        state.isAppLoading = false;
      })
      .addCase(setShowAnimation.fulfilled, (state, action) => {
        state.animation = action.payload;
      });
  },
});

//TODO: rewrite
export const getShowAnimation = (state) => state.configuration.animation;

export const getSurrentSymbol = (state) => {
  const currencyInfo = getCurrencyInfo(state);
  return currencyInfo.currentSymbol;
};

export const getCurrentSuffix = (state) => {
  const currencyInfo = getCurrencyInfo(state);
  return currencyInfo.currentSuffix;
};
// ------------------------------

export const { setAppLoading } = configurationSlice.actions;

export default configurationSlice.reducer;

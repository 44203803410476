export function random(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

//------------

//change bg color---------
// function lerpColor(color1, color2, t) {
//   const r1 = (color1 >> 16) & 0xff;
//   const g1 = (color1 >> 8) & 0xff;
//   const b1 = color1 & 0xff;

//   const r2 = (color2 >> 16) & 0xff;
//   const g2 = (color2 >> 8) & 0xff;
//   const b2 = color2 & 0xff;

//   const r = Math.round(r1 + t * (r2 - r1));
//   const g = Math.round(g1 + t * (g2 - g1));
//   const b = Math.round(b1 + t * (b2 - b1));

//   return (r << 16) | (g << 8) | b;
// }
// export const animateBackgroundColor = (delta, app) => {
//   // time += delta / 60; // Настройка скорости изменения цвета

//   // Рассчитывание коэффициента интерполяции (от 0 до 1)
//   const lerpFactor = Math.min(delta / 10, 1); // Настройка продолжительности

//   // Цвета в формате RGB
//   const startColor = { r: 80, g: 150, b: 253 }; // #5096FD
//   const endColor = { r: 17, g: 32, b: 53 }; // #112035

//   // Линейная интерполяция цветов
//   const r = Math.round(startColor.r + lerpFactor * (endColor.r - startColor.r));
//   const g = Math.round(startColor.g + lerpFactor * (endColor.g - startColor.g));
//   const b = Math.round(startColor.b + lerpFactor * (endColor.b - startColor.b));

//   // Применение нового цвета фона
//   app.renderer.backgroundColor = (r << 16) | (g << 8) | b;
// };

export const animateBackgroundColor = (timer, app) => {
  // Интервалы времени, когда нужно начать и закончить изменение цвета
  const startChangeTime = 2; // Начало изменения цвета
  const endChangeTime = 10; // Конец изменения цвета

  // Цвета в формате RGB
  const startColor = { r: 80, g: 150, b: 253 }; // #5096FD
  const endColor = { r: 17, g: 32, b: 53 }; // #112035
  // Если таймер меньше времени начала или больше времени конца, не менять цвет
  if (timer >= 0 && timer < startChangeTime) {
    // Применение начального цвета
    app.renderer.backgroundColor =
      (startColor.r << 16) | (startColor.g << 8) | startColor.b;
    return;
  }
  if (timer > endChangeTime) {
    // Применение конечного цвета
    app.renderer.backgroundColor =
      (endColor.r << 16) | (endColor.g << 8) | endColor.b;
    return;
  }

  // Рассчитывание коэффициента интерполяции (от 0 до 1) на основе времени
  const lerpFactor =
    (timer - startChangeTime) / (endChangeTime - startChangeTime);

  // Линейная интерполяция цветов
  const r = Math.round(startColor.r + lerpFactor * (endColor.r - startColor.r));
  const g = Math.round(startColor.g + lerpFactor * (endColor.g - startColor.g));
  const b = Math.round(startColor.b + lerpFactor * (endColor.b - startColor.b));

  // Применение нового цвета фона
  app.renderer.backgroundColor = (r << 16) | (g << 8) | b;
};

//-----end

export const isMobile = () => window.innerWidth <= 1350;

// const BG_WIDTH = 3030;
// const BG_HEIGHT = 2486;
export const BG_WIDTH = 3057;
export let BG_HEIGHT = 1602;

const SIDEBAR_WIDTH = 380;
const CONTROLS_HIEGHT = 88 + 168 + 32 + 24;

export const calculateScreen = (app) => {
  let Ay = (app.renderer.height - CONTROLS_HIEGHT) * 0.5 + 88;
  let Ax = app.renderer.width * 0.5 + SIDEBAR_WIDTH / 2;

  let By = app.renderer.height - (168 + 32 + 24 + 40);
  let deltaX = By - Ay;
  let Bx = Ax - deltaX - 130;

  if (isMobile()) {
    Ay = app.renderer.height / 2;
    Ax = app.renderer.width / 2;

    By = app.renderer.height - 20;
    deltaX = By - Ay;
    Bx = Ax - deltaX - 50;
  }

  return {
    By,
    Bx,
    Ax,
    Ay,
    deltaX,
  };
};

import React, { useMemo, useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { getSurrentSymbol, getCurrentSuffix } from '../../redux/ConfigurationSlice';
import {
    getBetCount,
    getTotalBank,
    getTookBet,
} from '../../redux/betTableSlice';
import {
    fetchBetHistory
} from '../../redux/betHistorySlice';

import config from '../../config';

import BetTable from './BetTable';
import MyBetsTable from './MyBetsTable';
import { useActions } from '../../hooks'


import './Sidebar.scss';


const Sidebar = () => {    
    const { t } = useTranslation();


    const betCount = useSelector(getBetCount);
    const totalBank = useSelector(getTotalBank);
    const tookBet = useSelector(getTookBet);    
    const currentSymbol = useSelector(getSurrentSymbol)
    const currentSuffix = useSelector(getCurrentSuffix);

    const actions = useActions({
        fetchBetHistory
    })

  const [activeTab, setActiveTab] = React.useState('all');

    useEffect(() => {
        if (activeTab === 'my') {
            actions.fetchBetHistory();
        }
    }, [activeTab]);


    const content = useMemo(() => {
        if (activeTab === 'all') {
            return <BetTable />
        }

        if (activeTab === 'my') {
            return <MyBetsTable />
        }
    }, [activeTab]);

  return (
    <div className='sidebar'>
      <h3 className='sidebar__title'>{t('SIDEBAR.ROUND_INFO')}</h3>
      <div className='round-info'>
        <div className='round-info__col'>
          <span className='round-info__label'>{t('SIDEBAR.BETS')}</span>
          <span className='round-info__value'>{betCount}</span>
        </div>
        <div className='divider-vertical'> </div>
        <div className='round-info__col'>
          <span className='round-info__label'>{t('SIDEBAR.TOOK_BET')}</span>
          <span className='round-info__value'>{tookBet}</span>
        </div>
        <div className='divider-vertical'></div>
        <div className='round-info__col'>
          <span className='round-info__label'>{t('SIDEBAR.TOTAL_BANK')}</span>
          <span className='round-info__value'>
            {currentSymbol}
            {totalBank}
            {currentSuffix}
          </span>
        </div>
      </div>
      <div className='tabs'>
        <button
          className={`tabs__tab ${
            activeTab === 'all' ? 'tabs__tab--avtive' : ''
          }`}
          onClick={() => setActiveTab('all')}
        >
          {t('SIDEBAR.ALL_BETS_TAB')}
        </button>
        <button
          className={`tabs__tab ${
            activeTab === 'my' ? 'tabs__tab--avtive' : ''
          }`}
          onClick={() => setActiveTab('my')}
        >
          {t('SIDEBAR.MY_BETS_TAB')}
        </button>
      </div>
      {content}
    </div>
  );
};

export default Sidebar;

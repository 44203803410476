import * as PIXI from "pixi.js";
import { Spine } from "pixi-spine";
import { random, isMobile } from "../utils.js";

const SCALE = 0.3;

class Saturn {
  constructor(spineData, app, multiplier) {
    this.spine = new Spine(spineData);
    this.app = app;

    if (isMobile()) {
      this.spine.scale.set(0.2);
    } else {
      this.spine.scale.set(0.3);
    }

    this.spine.state.setAnimation(0, "animation", true);

    this.resetPosition();
    this.spine.visible = multiplier > 8.5;
  }

  resetPosition() {
    this.spine.x = this.app.renderer.width - random(0, this.app.renderer.width);
    this.spine.y = -(500 * SCALE);
  }

  update(multiplier) {
    if (multiplier === -1) return;

    if (multiplier === 0) {
      this.spine.visible = false;
      this.resetPosition();
    }

    if (multiplier > 8.5) {
      this.spine.visible = true;
      this.spine.x -= 0.15;
      this.spine.y += 0.2;
    }
  }
}

export default Saturn;

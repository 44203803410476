import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HistoryItem } from './HistoryItem/HistoryItem';
import { useSelector } from 'react-redux';
import { getCrashHistory } from '../../redux/crashHistorySlice';
import './History.scss';

const History = () => {
  const crashHistory = useSelector(getCrashHistory);
  const arr = crashHistory.slice(0).reverse();

  return (
    <div className='history'>
      <div className={`history__items`}>
        {arr.map((crash, index, array) => {
          return <HistoryItem value={crash} key={index + uuidv4()} />;
        })}
      </div>
    </div>
  );
};

export default History;

import React from 'react';
import { Slide, toast } from 'react-toastify';

const Message = ({ closeToast, title, description }) => (
  <div>
    <h2 className='toast__title'>{title}</h2>
    <p className='toast__description'>{description}</p>
  </div>
);

export const errorToast = (title, description) => {
  toast.error(<Message title={title} description={description} />, {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    closeButton: false,
    theme: 'dark',
    transition: Slide,
    icon: ({ theme, type }) => (
      <img className='toast__icon' src={require('./alert.gif')} />
    ),
  });
};

export const winToast = (money, multiplier, t, currencyInfo) => {
  const { currentSymbol, currentSuffix } = currencyInfo;

  toast(
    <Message
      title={t('TOAST.WIN_TITLE', {
        money: currentSymbol + money.toFixed(2) + currentSuffix,
      })}
      description={t('TOAST.WIN_DESCRIPTION', { multiplier })}
    />,
    {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      theme: 'dark',
      transition: Slide,
      icon: ({ theme, type }) => (
        <img className='toast__icon' src={require('./Money.gif')} />
      ),
    }
  );
};

export const successToast = (title, description) => {
  toast.success(<Message title={title} description={description} />, {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    closeButton: false,
    theme: 'dark',
    transition: Slide,
    icon: ({ theme, type }) => (
      <img
        className='toast__icon success-icon'
        src={require('./success.gif')}
      />
    ),
  });
};

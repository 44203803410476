import * as PIXI from "pixi.js";
import { random, isMobile } from "../utils.js";

class Stars extends PIXI.Sprite {
  constructor(texture, app, multiplier) {
    super(texture);
    this.app = app;

    this.visible = multiplier > 8.5;

    if (isMobile()) {
      this.scale.set(0.4);
    } else {
      this.scale.set(0.9);
    }

    this.resetPosition();
  }

  resetPosition() {
    this.x = 0;
    this.y = -this.height;
  }

  update(multiplier) {
    if (multiplier === -1) return;

    if (multiplier === 0) {
      this.visible = false;
      this.resetPosition();
    }

    // this.x -= 0.15;
    // this.y += 0.13;

    if (multiplier > 8.5) {
      this.visible = true;
      this.y < 0 ? (this.y += 0.4) : (this.y = 0);
    }
  }
}

export default Stars;

import React from 'react';

const Checkbox = ({ label, checked, onChange, className, disabled }) => {
  return (
    <label
      className={`checkobx ${className} ${
        disabled ? 'checkobx--disabled' : null
      }`}
    >
      <span className='checkobx__label'>{label}</span>
      <input
        className='checkobx__input'
        type='checkbox'
        checked={checked}
        onChange={onChange}
        disabled={disabled ? 'disabled' : null}
      />
      <span className='checkobx__mark' />
    </label>
  );
};

export default Checkbox;

import React, { useState } from 'react';

import walletImg from './images/userWallet.svg';

import './UserInfo.scss';

import { useSelector } from 'react-redux';
import { useActions } from '../../hooks';
import {
  getUserData,
  getCurrencyInfo,
} from '../../redux/userSlice';


const UserInfo = () => {
  let params = new URL(document.location.toString()).searchParams;
  const showBalance = !params.has("hideBalance");

  const userData = useSelector(getUserData);
  const currencyInfo = useSelector(getCurrencyInfo);
  const { currentSymbol, currentSuffix } = currencyInfo;

  if (!showBalance) return null;

  return (
    <>
      <div className='game_user-info'>
        {userData && userData !== 'No User Authentication' ? (
          <div className='user-info'>
            <div>
              <div className='user-info__buttons'>
                <div className='user-info__wallet-container'>
                  <button className={`user-info__wallet-button`}>
                    <img src={walletImg} alt='wallet' />
                  </button>
                  <div className='user-info__value'>
                    {currentSymbol}
                    {userData.balance.toFixed(2)}
                    {currentSuffix}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='user-info'></div>
        )}
      </div>
    </>
  );
};

export default UserInfo;
import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { calculateScreen, isMobile } from '../utils.js';

class Ball {
  constructor(spineData, app, multiplier) {
    this.spine = new Spine(spineData);
    this.app = app;

    const { By, Bx, Ax, Ay } = calculateScreen(app);
this.spine.visible = true;
 this.isBoom = false;
    if (multiplier > 1) {
      this.spine.position.set(Ax, Ay);
      this.spine.visible = true;
      this.spine.state.setAnimation(0, 'fly', true);
      this.spine.state.timeScale = 0.8;
      this.isHit = true;
      this.scale = 0.8;
      this.spine.scale.set(this.scale);
    } else {
      this.spine.state.timeScale = 0.8;
      this.spine.scale.set(0.5);
      if (isMobile()) {
        this.spine.position.set(Ax, Ay);
      } else {
        this.spine.position.set(Bx + 220, By - 130);
      }
      this.spine.visible = false;
      this.isHit = false;
      this.scale = 0.5;
    }
  }

  resetPosition() {
    const { By, Bx, Ax, Ay } = calculateScreen(this.app);
    this.spine.position.set(Ax, Ay);
  }

  hit() {
    const { By, Bx, Ax, Ay } = calculateScreen(this.app);
    this.isBoom = false;
    if (isMobile()) {
      this.spine.position.set(Ax, Ay);
    } else {
      this.spine.position.set(Bx + 220, By - 130);
    }
    this.scale = 0.5;
    this.spine.visible = true;
    this.spine.state.setAnimation(0, 'fly', true);
    this.isHit = true;
    this.spine.state.timeScale = 0.8;
    this.spine.scale.set(this.scale);
  }

  bettingPhase() {
    this.spine.visible = false;
  }

  update(multiplier) {
    if (this.scale < 0.8) {
      this.scale += 0.001;
      this.spine.scale.set(this.scale);
    }

    if (multiplier == -1 && !this.isBoom) {
      this.isBoom = true;
      this.spine.scale.set(0.8);
      this.spine.state.setAnimation(0, 'boom', false);
      this.spine.state.timeScale = 1;
      return;
    }

    if (this.isHit && this.scale < 0.8) {
      const { By, Bx, Ax, Ay } = calculateScreen(this.app);
      if (this.spine.x < Ax) {
        this.spine.x += 1;
        this.spine.y -= 1;
      }
    }
  }
}

export default Ball;

import React from 'react';

export const rowColor = (crash) => {
    if (crash < 10 && crash >= 2) return 'table__row--big';

    if (crash >= 10) return 'table__row--very-big';

    return '';
};


export const MultiplierValue = ({ value }) => {
    const calColor = (crash) => {
        if (crash < 10 && crash >= 2) return 'multiplier--big';

        if (crash >= 10) return 'multiplier--very-big';

        return '';
    };

    return (
        <div className={`multiplier ${calColor(value)}`}>
            <span>x{value}</span>
        </div>
    );
};

export default MultiplierValue;
import React from 'react';
import { useSelector } from 'react-redux';

import { useGame } from '../../contexts/GameContext';

import './GameStatus.scss';
import { useTranslation } from 'react-i18next';

const GameStatus = () => {
  // console.count('GameStatus');
  const { t } = useTranslation();

  const { liveMultiplier, bettingPhaseTime, gameStatus } = useSelector(
    (state) => state.bets
  );

  // const {
  //   // gameStatus,
  //   // bettingPhaseTime,
  //   // liveMultiplier
  // } = useGame();

  switch (gameStatus) {
    case 'start_betting_phase': {
      // half time
      return (
        <div className="game-status game-status__half-time">
          <h1>
            {bettingPhaseTime === 'Starting...' 
            ? 0
            : (+bettingPhaseTime).toFixed(0)}
          </h1>
          <span>{t('GAME_STATUS.HALF_TIME')}</span>
        </div>
      );
    }
    case 'end': {
      //starting
      return (
        <div className="game-status game-status__text game-status__starting">
          <h1>{bettingPhaseTime}</h1>
        </div>
      );
    }
    case 'start_multiplier_count': {
      // x100
      return (
        <div className="game-status">
          <h1>x{liveMultiplier}</h1>
        </div>
      );
    }

    case 'stop_multiplier_count': {
      // x 100 end
      return (
        <div className="game-status game-status--end">
          <h1>x{liveMultiplier}</h1>
          <span>{t('GAME_STATUS.BURST')}</span>
        </div>
      );
    }

    default:
      return null;
  }
};

export default GameStatus;

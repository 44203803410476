import React from 'react';
import Checkbox from './Checkbox';
import { useTranslation } from 'react-i18next';

const AutoBet = ({ checked, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className={`control ${checked ? 'control--fild' : ''} `}>
      <div className='control__main'>
        <Checkbox
          label={t('BET.AUTO_BET')}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default AutoBet;

import * as PIXI from "pixi.js";
import { random } from '../utils.js'

class Bird extends PIXI.Sprite {
  constructor(texture, app, multiplier) {
    super(texture);
    this.app = app;

    this.visible = multiplier > 1.3 && multiplier < 10;
    this.scale.set(0.1);
    this.resetPosition();

    this.showCount = 2;
    // const variants = [
    //   [7, 10],
    //   [3, 7],
    //   [1.4, 3],
    // ];
  }

  resetPosition() {
    this.x = random(100, this.app.renderer.width - 100);
    this.y = -this.height;
  }

  update(multiplier) {
    if (multiplier === -1) return;

    if (multiplier === 0) {
      this.visible = false;
      this.resetPosition();
      this.showCount = 2;
      this.min = random(2, 8);
      this.max = this.min + 2;
    }

    // const v = this.variants[this.showCount - 1];

    if (this.showCount > 0) {
      this.x += 1;
      this.y += 5;
    }

    if (this.y > this.app.screen.height + this.height * 2) {
      if (multiplier > this.min && multiplier < this.max) {
        this.showCount -= 1;
        this.visible = true;
        this.resetPosition();
      } else {
        this.visible = false;
      }
    }
  }
}

export default Bird;
import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
  getCurrentSymbol,
  getCurrentSuffix,
} from '../../redux/ConfigurationSlice';
import { useActions } from '../../hooks';

import { setGetInfo } from '../../redux/provablyFairSlice';

import { getBetHistory } from '../../redux/betHistorySlice';

import config from '../../config';

import MultiplierValue, { rowColor } from './MultiplierValue';

const MyBetsTable = () => {
  console.count('MyBetsTable');

  const { t } = useTranslation();

  const actions = useActions({
    setGetInfo,
  });

  const betHistory = useSelector(getBetHistory);
  const currentSuffix = useSelector(getCurrentSuffix);

  const [showAll, setShowAll] = useState(false);

  const showHistoryInfo = (message) => {
    if (!config.probablyFair) return;

    if (message.odds) {
      actions.setGetInfo(message);
    }
  };

  const visibleBetHistory = showAll ? betHistory : betHistory.slice(0, 10);

  return (
    <>
      <div className='table__header'>
        <div>{t('SIDEBAR.TABLE_TIME')}</div>
        <div>{t('SIDEBAR.TABLE_BET')}</div>
        <div>{t('SIDEBAR.TABLE_MULTI')}</div>
        <div>{t('SIDEBAR.TABLE_CASHOUT')}</div>
      </div>
      {betHistory.length > 0 ? (
        <>
          <div className='table__content'>
            {visibleBetHistory.map((message) => {
              return (
                <div
                  key={uuidv4()}
                  className={`table__row ${rowColor(
                    message.cashout_multiplier
                  )}`}
                  onClick={() => showHistoryInfo(message)}
                >
                  <div className='table__col'>
                    {moment(message.the_time).format('h:mm:ss')}
                  </div>
                  <div className='table__col'>
                    {message.bet_amount.toFixed(2)}
                  </div>
                  <div
                    className={`table__col ${
                      message.cashout_multiplier ? 'table__col--control' : ''
                    }`}
                  >
                    {message.cashout_multiplier ? (
                      <MultiplierValue
                        value={message.cashout_multiplier.toFixed(2)}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className='table__col'>
                    {message.profit
                      ? `${(
                          message.bet_amount * message.cashout_multiplier
                        ).toFixed(2)}`
                      : '-'}
                  </div>
                </div>
              );
            })}
          </div>
          {!showAll && betHistory.length > 10 && (
            <button className='show-more-btn' onClick={() => setShowAll(true)}>
              {t('SIDEBAR.SHOW_MORE_BUTTON')}
            </button>
          )}
          {showAll && betHistory.length > 10 && (
            <button className='show-more-btn' onClick={() => setShowAll(false)}>
              {t('SIDEBAR.SHOW_LESS_BUTTON')}
            </button>
          )}
        </>
      ) : null}
    </>
  );
};

export default MyBetsTable;

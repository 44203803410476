import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import moment from 'moment';

import * as api from './api';

import { useSelector } from 'react-redux';

import {
  setbBettingPhase,
  setLiveMultiplier,
  setBetsActive,
  setLiveMultiplierSwitch,
  setBettingPhaseTime,
  setGlobalTimeNow,
  setGamePhaseTimeElapsed,
  setGameStatus,
} from '../redux/betsSlice';

import { fetchConfiguration, setAppLoading } from '../redux/ConfigurationSlice';

import {
  setLiveBettingTable,
  addLiveBettingTable,
  updateLiveBettingTable,
} from '../redux/betTableSlice';
import { setNextServerSeed } from '../redux/provablyFairSlice';
import { fetchUser } from '../redux/userSlice';
import { setCrashHistory } from '../redux/crashHistorySlice';
import { useActions } from '../hooks';
import { startBettingPhase } from '../game/animation/Animation';

function calculateMultiplier(sec) {
  var r = 0.00006;
  return (100 * Math.pow(Math.E, sec * 1000 * r)) / 100;
}

export const GameContext = createContext({});

export const GameProvider = ({ children, socket }) => {
  const actions = useActions({
    setLiveBettingTable,
    setNextServerSeed,
    fetchUser,

    setbBettingPhase,
    setBettingPhaseTime,
    setLiveMultiplier,
    setBetsActive,
    setLiveMultiplierSwitch,
    setCrashHistory,
    setGlobalTimeNow,
    setGamePhaseTimeElapsed,
    setGameStatus,
    addLiveBettingTable,
    updateLiveBettingTable,

    fetchConfiguration,
    setAppLoading,
  });

  const {
    betsActive,
    liveMultiplier,
    liveMultiplierSwitch,
    bBettingPhase,
    globalTimeNow,
    gameStatus,
  } = useSelector((state) => state.bets);

  const get_game_status = () => {
    api.get_game_status().then((res) => {
      if (res.data.phase === 'betting_phase') {
        actions.setGlobalTimeNow(res.data.info);
        actions.setbBettingPhase(true);
      } else if (res.data.phase === 'game_phase') {
        actions.setGlobalTimeNow(res.data.info);
        actions.setLiveMultiplierSwitch(true);
        // actions.setBetsActive(res.data.betsActive);
        actions.setLiveBettingTable(res.data.liveBettorsTable);
      }
    });
  };

  useEffect(() => {
    socket.on('next_hash', function (data) {
      actions.setNextServerSeed(data);
    });

    socket.on('start_multiplier_count', function (data) {
      actions.setGlobalTimeNow(Date.now());
      actions.setLiveMultiplierSwitch(true);
      actions.setGameStatus('start_multiplier_count');
    });

    socket.on('start_betting_phase', function (data) {
      actions.setGlobalTimeNow(Date.now());
      actions.setLiveMultiplier('Starting...');

      actions.setbBettingPhase(true);
      actions.setLiveBettingTable([]);

      actions.setGameStatus('start_betting_phase');
      startBettingPhase();
    });

    socket.on('receive_live_betting_table', (data) => {
     actions.setLiveBettingTable(data);
    });

    socket.on('add_live_betting_table', (data) => {
      actions.addLiveBettingTable(data);
    });

    socket.on('update_live_betting_table', (data) => {
      actions.updateLiveBettingTable(data);
    });

    socket.on('stop_multiplier_count', function (data) {
      actions.setLiveMultiplier(data);
      actions.setLiveMultiplierSwitch(false);

      actions.setGameStatus('stop_multiplier_count');
    });

    socket.on('crash_history', function (data) {
      actions.setCrashHistory(data);
    });

    socket.on('update_user', function (data) {
      actions.fetchUser();
    });

    socket.on('connect_error', function (data) {
      actions.setAppLoading(true);
    });
    socket.on('disconnect', function (data) {
      actions.setAppLoading(true);
    });

    actions.fetchUser();

    socket.on('connect', () => {
      get_game_status();
      // actions.setAppLoading(false);
      actions.fetchConfiguration();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let bettingInterval = null;

    if (bBettingPhase) {
      bettingInterval = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 1000.0;
        let time_remaining = (3 - time_elapsed).toFixed(2); // 3 = cash phase + bet phase - 1  = (2 + 3 - 1)
        if (time_remaining < 0) {
          actions.setbBettingPhase(false);
          // setGameStatus("end");
        } else {
          actions.setBettingPhaseTime(time_remaining);
        }
      }, 10);
    }
    return () => {
      clearInterval(bettingInterval);
      actions.setBettingPhaseTime('Starting...');
    };
  }, [bBettingPhase]);

  useEffect(() => {
    let gameCounter = null;
    if (liveMultiplierSwitch) {
      actions.setLiveMultiplier('1.00');

      gameCounter = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 1000.0;
        actions.setGamePhaseTimeElapsed(time_elapsed);
        actions.setLiveMultiplier(calculateMultiplier(time_elapsed).toFixed(2));
        // console.log('calculateMultiplier(time_elapsed) ', calculateMultiplier(time_elapsed));
        if (gameStatus === '') {
          actions.setGameStatus('start_multiplier_count');
        }
      }, 50);
    }
    return () => {
      clearInterval(gameCounter);
    };
  }, [liveMultiplierSwitch]);

  return <>{children}</>;
};

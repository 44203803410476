import i18next from 'i18next';

export const getSelectedBalance = (user) => {
  // if (user?.selectedBalanceType === 'main') {
  //   return user.mainBalance;
  // } else {
  //   return user.bonusBalance;
  // }
  return user.balance;
};

export const changeLanguage = (currency, currentLanguage) => {
  const currencyToLanguage = {
    USD: 'en',
    MXN: 'es',
    PYG: 'es',
    BRL: 'pt',
  };

  const targetLanguage = currencyToLanguage[currency];

  if (targetLanguage && currentLanguage !== targetLanguage) {
    i18next.changeLanguage(targetLanguage, (err) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }
};

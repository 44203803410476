import * as PIXI from "pixi.js";
import { random } from '../utils.js'

class Cloud extends PIXI.Sprite {
  constructor(texture, app, multiplier) {
    super(texture);
    this.app = app;

    this.visible = multiplier > 1.1 && multiplier < 10;
    this.scale.set(0.4);
    this.resetPosition();
  }

  resetPosition() {
    this.x = random(0, this.app.renderer.width * 2);
    this.y = -this.height;
  }

  update(multiplier) {
    if (multiplier === -1) return;

    if (multiplier === 0) {
      this.visible = false;
      this.resetPosition();
    }

    this.x -= 5;
    this.y += 5;

    if (this.y > this.app.screen.height + this.height * 2) {
      if (multiplier > 1 && multiplier < 10) {
        this.visible = true;
        this.resetPosition();
      } else {
        this.visible = false;
      }
    }
  }
}

export default Cloud;
import React from 'react';
import './HistoryItem.scss';

export const HistoryItem = ({ value }) => {
  const calColor = (crash) => {
    if (crash < 10 && crash > 2) return 'history__item--big';

    if (crash > 10) return 'history__item--very-big';

    return '';
  };

  return (
    <div className={`history__item ${calColor(value)}`}>
      <span>x{value}</span>
    </div>
  );
};
